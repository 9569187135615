/* Chatbot.css */
.chatbot {
  position: fixed;
  bottom: 20px;
  right: 30px;
  width: 4rem;
  height: 4rem;
  background-color: #000;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  flex-direction: column;
}

.chatbot-collapsed {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.chatbot-logo {
  width: 80px;
  height: 80px;
  margin-bottom: 5px;
}

.chatbot.expanded {
  width: 20rem;
  height: 30rem;
  border-radius: 10px;
  background-color: #fff;
  color: #000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.chatbot-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.chatbot-header {
  background-color: #000;
  color: white;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.chatbot-header-logo {
  display: flex;
  align-items: center;
}

.chatbot-header-logo-img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.chatbot-header-title {
  flex-grow: 1;
  margin-left: 8px;
  font-size: 1.5rem;
}

.chatbot-close-button {
  background: none;
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
}

.chatbot-body {
  padding: 8px;
  flex-grow: 1;
  overflow-y: auto;
  background-color: #fff;
  color: black;
  cursor: default;
}

.chatbot-body::-webkit-scrollbar {
  width: 5px;
}

.chatbot-body::-webkit-scrollbar-thumb {
  background-color: #000;
}

.message {
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
  font-size: 1rem;
}

.bot-message {
  justify-content: flex-start;
  text-align: start;
  align-items: center;
}

.user-message {
  flex-direction: row-reverse;
  align-items: center;
}

.message-logo {
  width: 20px;
  height: 20px;
  margin: 0 8px;
}

.message-text {
  background-color: #f1f1f1;
  padding: 6px;
  border-radius: 6px;
  max-width: 70%;
  font-size: 1rem;
}

.bot-message .message-text {
  background-color: #e1e1e1;
}

.user-message .message-text {
  background-color: #007bff;
  color: white;
}

.chatbot-footer {
  padding: 8px;
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chatbot-input {
  flex-grow: 1;
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  margin-right: 8px;
}

.chatbot-send-button {
  padding: 6px 12px;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.loader {
  text-align: center;
  font-size: 14px;
  color: #555;
  margin: 10px 0;
}

.chatbot-send-button[disabled] {
  cursor: not-allowed;
  opacity: 0.6;
}